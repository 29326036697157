import React, { Component } from 'react';
import Card from './Card.js';

class Cards extends Component {
    constructor(props) {
        super(props);
        this.state = {stats: this.props.stats, updatesPerSec: 14};

        this.animateCounters = this.animateCounters.bind(this);
    }

    componentDidMount(){
        let {updatesPerSec} = this.state;
        let interval = setInterval(this.animateCounters, 1000/updatesPerSec);
        this.setState({interval: interval});
    }

    componentWillUnmount(){
        let {interval, stats} = this.state;
        if(interval){
            clearInterval(interval);
        }
        for(let x = 0; x < stats.length; x++){
            stats[x].counter = 0;
        }
    }

    animateCounters(){
        let {stats, updatesPerSec} = this.state;

        for(let x = 0; x < stats.length; x++){
            stats[x].counter += stats[x].rate/updatesPerSec;
        }

        this.setState({stats: stats});
    }

    commas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    
    render() {
        let {stats} = this.state;
        let {cols} = this.props;
        return (
            <div style={{gridTemplateColumns: `repeat(${cols}, 1fr)`}} className="cards">
                {stats.map(function(stat){
                    let statText = stat.getText(this.commas(Math.floor(stat.counter)));
                    return <Card image={stat.image} 
                    stat={statText}
                    rate={stat.rate}
                    source={stat.source}/>;
                }.bind(this))}
            </div>
        );
    }
}

export default Cards;