import React, { Component } from 'react';
import {Request} from './Request.js';
import styled from 'styled-components';
import Since from './Since.js';
import Title from './Title.js';
import Header from './Header.js';
import Footer from './Footer.js';
import MetaTags from './MetaTags.js';

const Explanation = styled.div`
    background: white;
    padding: 20px;
    line-height: 1.4em;
    margin-bottom: 12px;
    text-align: center;

    a{
        color: #333333;
    }
`


class SpotifyCharts extends Component {
    constructor(props){
        super(props);
        this.state = {songs: [], updatesPerSec: 16};
        this.animatePlays = this.animatePlays.bind(this);
    }

    async componentDidMount(){
        let {updatesPerSec} = this.state;
        let data = await Request("/spotify-charts");
        let stats = [];
        for(let x = 0; x < data.length; x++){
            data[x].rate = data[x].plays/24/60/60;
            data[x].current = 0;
        }
        
        this.setState({stats: stats});

        this.setState({songs: data}, function(){
            let interval = setInterval(this.animatePlays, 1000/updatesPerSec);
            this.setState({interval: interval});
        }.bind(this));
    }

    componentWillUnmount(){
        let {interval} = this.state;
        clearTimeout(interval);
    }

    animatePlays(){
        let {songs, updatesPerSec} = this.state;
        for(let x = 0; x < songs.length; x++){
            songs[x].current += songs[x].rate/updatesPerSec;
        }
        this.setState({songs: songs});
    }

    render() {
        let {songs, stats} = this.state;
        return (
            <>
                <MetaTags 
                    title="Spotify Charts - Every Second"
                    description="See how many plays the top songs on Spotify are getting every second."
                    socialDesc="See how many plays the top songs on Spotify are getting every second."
                    favicon="/spotify-charts/boombox.png"
                    canonical="/spotify-charts"
                />
                <Header/>
                <div className="body-container">
                <Title icon="/spotify-charts/boom-box.svg" title="Spotify Charts - Every Second"/>
                <Since color="#2ed573"/>
                <Explanation>
                    This page shows how many plays the top 25 tracks on Spotify are getting every second. The data is updated daily from <a target="_blank" href="https://spotifycharts.com/regional/global/daily/latest">Spotify Charts</a>.
                </Explanation>
                    {songs.length != 0 ? songs.map(function(song, i){
                        return <SpotifySong song={song}/>
                    }) : [...Array(25)].map(function(){
                        return <SpotifySong/>
                    })}
                </div>
                <Footer/>
            </>
        );
    }
}

const SongContainer = styled.div`
    margin: 0px auto 5px auto;
    display: grid;
    grid-gap: 15px 15px;
    grid-template-columns: 70px auto 140px;
    grid-template-rows: 70px;
    background-color: white;
    vertical-align: middle;

    @media only screen and (max-width: 700px) {
        grid-template-columns: 70px auto 100px;
        grid-template-rows: 70px;
    }
`

const SongName = styled.div`
    font-size: 18px;
    display: flex;
    align-self: center;
    font-weight: 500;
    text-overflow: ellipsis;

    @media only screen and (max-width: 700px) {
        font-size: 16px;
    }
`

const SongAuthor = styled.div`
    font-size: 16px;
    font-weight: normal;
    display: flex;
    align-self: center;
    margin-left: 5px;

    @media only screen and (max-width: 700px) {
        display: none;
    }
`

const SongPlays = styled.span`
    font-size: 18px;
    font-weight: 500;
    text-align: right;
    display: flex;
    justify-self: right;
    align-self: center;
    padding-right: 25px;

    @media only screen and (max-width: 700px) {
        font-size: 16px;
        padding-right: 15px;
    }
`

const SongImage = styled.img`
    width: 100%;
    height: 100%;
`

class SpotifySong extends Component {

    prettyNum(num){
        let x = Math.floor(num);
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {
        let {song} = this.props;
        return (
            <SongContainer>
                {song ? <>
                <SongImage src={song.image}/>
                <SongName>{song.track}<SongAuthor>{" by " + song.author}</SongAuthor></SongName>
                <SongPlays>{this.prettyNum(song.current) + " plays"}</SongPlays></> : <></>}
            </SongContainer>
        );
    }
}

export default SpotifyCharts;