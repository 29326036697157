import React from "react";
import { Router, Route, Link } from "react-router-dom";
import createHistory from 'history/createBrowserHistory';
import './App.css';
import ReactGA from 'react-ga';
import Home from './components/Home.js';
import Apple from './components/Apple.js';
import Messenger from './components/Messenger.js';
import McDonalds from './components/McDonalds.js';
import DisneyWorld from './components/DisneyWorld.js';
import Uber from './components/Uber.js';
import Reddit from './components/Reddit.js';
import Instagram from './components/Instagram.js';
import Internet from './components/Internet.js';
import AnimalHeartbeats from './components/AnimalHeartbeats.js';
import PlanetSizes from './components/PlanetSizes.js';
import MaxBurgers from './components/MaxBurgers.js';
import YouTube from './components/YouTube.js';
import FramesPerSecond from './components/FramesPerSecond';
import SpotifyCharts from './components/SpotifyCharts';
import Earth from './components/Earth';
import HumanBody from './components/HumanBody';
import DiseaseControl from './components/DiseaseControl';


const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

ReactGA.initialize("UA-42651105-12");

const history = createHistory()
history.listen(location => {
  console.log(location.pathname);
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

class App extends React.Component {

  componentDidMount(){
    ReactGA.pageview(window.location.pathname);
  }

  render(){
    return (
      <Router history={history}>
          <Route component={ScrollToTop} />
          <Route exact path="/" component={Home} />
          <Route exact path="/apple" component={Apple} />
          <Route exact path="/messenger" component={Messenger} />
          <Route exact path="/mcdonalds" component={McDonalds} />
          <Route exact path="/disney-world" component={DisneyWorld} />
          <Route exact path="/uber" component={Uber} />
          <Route exact path="/reddit" component={Reddit} />
          <Route exact path="/instagram" component={Instagram} />
          <Route exact path="/the-internet" component={Internet} />
          <Route exact path="/animal-heartbeats" component={AnimalHeartbeats} />
          <Route exact path="/youtube" component={YouTube} />
          <Route exact path="/planet-sizes" component={PlanetSizes} />
          <Route exact path="/maxburgers" component={MaxBurgers} />
          <Route exact path="/frames-per-second" component={FramesPerSecond} />
          <Route exact path="/spotify-charts" component={SpotifyCharts} />
          <Route exact path="/earth" component={Earth} />
          <Route exact path="/your-body" component={HumanBody} />
          <Route exact path="/disease-control" component={DiseaseControl} />
      </Router>
    );
  }
}

export default App;