import React, { Component } from 'react';
import Cards from './Cards.js';
import Stat from './Stat.js';
import Since from './Since.js';
import Title from './Title.js';
import Header from './Header.js';
import * as StatHelpers from './StatHelpers.js';
import MetaTags from './MetaTags.js';
import Footer from './Footer.js';

let {year, quarter, month, day, statNum} = StatHelpers;

let stats = [
    new Stat(75, "/mcdonalds/burger.svg", "https://www.thefiscaltimes.com/Articles/2012/04/30/13-Disturbing-Facts-About-McDonalds", function(num){
        return <>{statNum(num)} hamburgers sold</>;
    }),
    new Stat(year(550000000), "/mcdonalds/big-mac.svg", "https://www.investopedia.com/articles/markets/111015/mcdonalds-vs-burger-king-comparing-business-models.asp", function(num){
        return <>{statNum(num)} Big Macs sold</>;
    }),
    new Stat(day(9000000), "/mcdonalds/fries.svg", "https://thenextweb.com/insider/2015/05/09/what-mcdonalds-can-teach-us-about-selling/#gref", function(num){
        return <>{statNum(num)} pounds of fries sold</>;
    }),
    new Stat(year(500000000), "/mcdonalds/coffee.svg", "https://www.inc.com/peter-economy/mcdonalds-just-made-a-stunning-announcement-that-will-completely-change-future-of-fast-food.html", function(num){
        return <>{statNum(num)} cups of coffee sold</>;
    }),
    new Stat(day(3200000), "/mcdonalds/happy-meal.svg", "https://www.nrn.com/quick-service/data-happy-meal-vital-mcdonald-s-success", function(num){
        return <>{statNum(num)} Happy Meals sold</>;
    }),
    new Stat(day(69000000), "/mcdonalds/cashier.svg", "https://www.chicagotribune.com/business/chi-mcdonalds-60-years-20150415-story.html", function(num){
        return <>{statNum(num)} customers served</>;
    }),
    new Stat(year(1600000000), "/mcdonalds/cow.svg", "https://newfoodeconomy.org/mcdonalds-greenhouse-gas-emissions-reduction-pledge-beef/", function(num){
        return <>{statNum(num)} pounds of beef used</>;
    }),
    new Stat(year(2120000000), "/mcdonalds/fried-egg.svg", "https://www.reuters.com/article/us-mcdonalds-eggs-idUSKCN0R91SJ20150909", function(num){
        return <>{statNum(num)} eggs used</>;
    }),
    new Stat(year(3000000000), "/mcdonalds/happy-meal.svg", "https://www.inc.com/peter-economy/mcdonalds-just-made-a-stunning-announcement-that-will-completely-change-future-of-fast-food.html", function(num){
        return <>{statNum(num)} pounds of packaging used</>;
    }),
    new Stat(year(22000000), "/mcdonalds/climate-change.svg", "https://newfoodeconomy.org/mcdonalds-greenhouse-gas-emissions-reduction-pledge-beef/", function(num){
        return <>{statNum(num)} metric tons of greenhouse gas emissions</>;
    }),
];

class McDonalds extends Component {
    render() {
        return (
            <>
                <MetaTags 
                    title="McDonalds - Every Second"
                    description="See how many burgers and Big Macs McDonalds sells every second, and 10+ other stats!"
                    socialDesc="How many burgers does McDonalds sell every second?"
                    favicon="/mcdonalds/burger.png"
                    canonical="/mcdonalds"
                />
                <Header/>
                <div className="body-container">
                    <Title icon="/mcdonalds/burger.svg" title="McDonalds - Every Second"/>
                    <Since color="#efa335"/>
                    <Cards cols={2} stats={stats}></Cards>
                </div>
                <Footer/>
            </>
        );
    }
}

export default McDonalds;