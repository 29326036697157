let base = "http://127.0.0.1:3001"

if (process.env.NODE_ENV === 'production') {
    base = "https://every-second.herokuapp.com";
}

export async function Request(path){
    let response = await fetch(base + path);
    let json = await response.json();
    return json;
}