import React, { Component } from 'react';

class MaxBurgers extends Component {
    render() {
        return (
            <iframe style={{width: "100vw", height:"100vh"}} src="https://everysecond.max.se/" frameborder="0px"></iframe>
        );
    }
}

export default MaxBurgers;