import React, { Component } from 'react';

class Title extends Component {
    render() {
        let {title, icon} = this.props;

        return (
            <div className="title">
                <img className="title-icon" src={icon}/>
                {title}
            </div>
        );
    }
}

export default Title;