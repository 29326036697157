import React, { Component } from 'react';
import Cards from './Cards.js';
import Stat from './Stat.js';
import Since from './Since.js';
import Title from './Title.js';
import Header from './Header.js';
import Footer from './Footer.js';
import MetaTags from './MetaTags.js';
import * as StatHelpers from './StatHelpers.js';

let {year, quarter, month, day, hour, minute, statNum} = StatHelpers;

let stats = [
    new Stat(100, "/earth/lightning.svg", "https://www.nationalgeographic.com/environment/natural-disasters/lightning/", function(num){
        return <>{statNum(num)} lightning strikes</>;
    }),
    new Stat(year(536000000000000000), "/earth/rain.svg", "https://hypertextbook.com/facts/2008/VernonWu.shtml", function(num){
        return <>{statNum(num)} liters of rainfall</>;
    }),
    new Stat(64800000, "/earth/sun.svg", "https://www.worldenergy.org/wp-content/uploads/2013/10/WER_2013_8_Solar_revised.pdf", function(num){
        return <>{statNum(num)} gigawatts of solar energy hit the surface</>;
    }),
    new Stat(day(25000000), "/earth/comet.svg", "http://abyss.uoregon.edu/~js/ast121/lectures/lec18.html", function(num){
        return <>{statNum(num)} meteors entered the atmosphere</>;
    }),
    new Stat(hour(1609344), "/earth/day-and-night.svg", "https://www.scientificamerican.com/article/how-fast-is-the-earth-mov/", function(num){
        return <>Rotated {statNum(num)}m at the equator</>;
    }),
    new Stat(30.5775, "/earth/galaxy.svg", "http://curious.astro.cornell.edu/about-us/41-our-solar-system/the-earth/orbit/91-at-what-speed-does-the-earth-move-around-the-sun-beginner", function(num){
        return <>Moved {statNum(num)}km relative to the sun</>;
    }),
    new Stat(year(15000000000), "/earth/log.svg", "https://time.com/4019277/trees-humans-deforestation/", function(num){
        return <>{statNum(num)} trees cut down</>;
    }),
    new Stat(year(8000000 * 907.185), "/earth/plastic-bottle.svg", "https://time.com/4019277/trees-humans-deforestation/", function(num){
        return <>{statNum(num)}kg of plastic put in the ocean</>;
    }),/*
    new Stat(4.3, "/earth/baby.svg", "http://curious.astro.cornell.edu/about-us/41-our-solar-system/the-earth/orbit/91-at-what-speed-does-the-earth-move-around-the-sun-beginner", function(num){
        return <>{statNum(num)} humans born</>;
    }),
    new Stat(1.8, "/earth/grave.svg", "http://curious.astro.cornell.edu/about-us/41-our-solar-system/the-earth/orbit/91-at-what-speed-does-the-earth-move-around-the-sun-beginner", function(num){
        return <>{statNum(num)} humans passed away</>;
    }),*/
];

class Earth extends Component {
    render() {
        return (
            <>
                <MetaTags 
                    title="Earth - Every Second"
                    description="See what happens every second on Earth. Including how many lightning strikes hit earth every second!"
                    socialDesc="What happens every second on earth?"
                    favicon="/earth/earth.png"
                    canonical="/earth"
                />
                <Header/>
                <div className="body-container">
                    <Title icon="/earth/earth.svg" title="Earth - Every Second"/>
                    <Since color="#26de81"/>
                    <Cards cols={2} stats={stats}></Cards>
                </div>
                <Footer/>
            </>
        );
    }
}

export default Earth;