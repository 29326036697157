import React, { Component } from 'react';
import Cards from './Cards.js';
import Stat from './Stat.js';
import Since from './Since.js';
import Title from './Title.js';
import Header from './Header.js';
import Footer from './Footer.js';
import MetaTags from './MetaTags.js';
import * as StatHelpers from './StatHelpers.js';

let {year, quarter, month, day, hour, minute, statNum} = StatHelpers;

let stats = [
    new Stat(minute(75), "/your-body/cardiogram.svg", "https://www.heart.org/en/health-topics/high-blood-pressure/the-facts-about-high-blood-pressure/all-about-heart-rate-pulse", function(num){
        return <>{statNum(num)} heartbeats</>;
    }),
    new Stat(minute(30000), "/your-body/skin-cells.svg", "https://sciencing.com/life-span-skin-cells-5114345.html", function(num){
        return <>{statNum(num)} skin cells shed</>;
    }),
    new Stat(minute(75 * 70), "/your-body/heart.svg", "http://www.vascularconcepts.com/content/pages.php?pg=patients_cardio_system&page=cardiac_cycle", function(num){
        return <>{statNum(num)} mL of blood pumped by your heart</>;
    }),
    new Stat(day(141900), "/your-body/kidney.svg", "https://www.niddk.nih.gov/health-information/kidney-disease/kidneys-how-they-work", function(num){
        return <>{statNum(num)} mL of blood filtered by your kidneys</>;
    }),
    new Stat(minute(27940), "/your-body/man.svg", "https://www.huffpost.com/entry/body-in-a-minute_n_6327540", function(num){
        return <>{statNum(num)} micrometers of hair grown</>;
    }),
    new Stat(day(1000000), "/your-body/open-mouth.svg", "https://www.thejpd.org/article/S0022-3913(01)54032-9/pdf", function(num){
        return <>{statNum(num)} microliters of saliva produced</>;
    }),
    new Stat(day(200000000000), "/your-body/red-blood-cells.svg", "https://www.britannica.com/science/blood-cell-formation", function(num){
        return <>{statNum(num)} red blood cells produced</>;
    }),
    new Stat(day(10000000000), "/your-body/white-blood-cell.svg", "https://www.britannica.com/science/blood-cell-formation", function(num){
        return <>{statNum(num)} white blood cells produced</>;
    }),
    new Stat(minute(86000000000 * 20), "/your-body/neuron.svg", "http://www.human-memory.net/brain_neurons.html", function(num){
        return <>{statNum(num)} neurons fired</>;
    }),
    new Stat(20, "/your-body/brain.svg", "https://hypertextbook.com/facts/2001/JacquelineLing.shtml", function(num){
        return <>{statNum(num)} joules used by your brain</>;
    }),
    new Stat(day(1000000000000), "/your-body/DNA.svg", "https://www.theatlantic.com/science/archive/2018/05/your-body-acquires-trillions-of-new-mutations-every-day/559472/", function(num){
        return <>{statNum(num)} DNA mutations</>;
    }),
    new Stat(10000000, "/your-body/vision.svg", "https://www.britannica.com/science/information-theory/Physiology", function(num){
        return <>{statNum(num)} bits of visual data processed</>;
    })/*
    new Stat(10000000000000000, "/the-internet/group.svg", "http://www.human-memory.net/brain_neurons.html", function(num){
        return <>{statNum(num)} synapses fired</>;
    }),*/
];

class HumanBody extends Component {
    render() {
        return (
            <>
                <MetaTags 
                    title="Your Body - Every Second"
                    description="See all the things happening in your human body every second."
                    socialDesc="What happens in your body every second?"
                    favicon="/your-body/heart.png"
                    canonical="/your-body"
                />
                <Header/>
                <div className="body-container">
                    <Title icon="/your-body/heart.svg" title="Your Body - Every Second"/>
                    <Since color="#e66767"/>
                    <Cards cols={2} stats={stats}></Cards>
                </div>
                <Footer/>
            </>
        );
    }
}

export default HumanBody;