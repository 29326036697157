import React, { Component } from 'react';

class Card extends Component {
    constructor(props) {
        super(props);
    }

    roundNum(num){
        if(num < 10){
            return Math.round( num * 10 ) / 10
        }else{
            return Math.round(num);
        }
    }


    getRate(rate){
        if(rate >= 1){
            return this.roundNum(rate) + "/sec";
        }
        rate *= 60;
        if(rate >= 1){
            return this.roundNum(rate) + "/min"
        }
    }
    
    render() {
        let {image, stat, source, rate} = this.props;
        return (
            <div className="card">
                <img className="card-image" src={image}/>
                <div className="card-stat">{stat}</div>
                <a className="card-source" target="_blank" href={source}>Source</a>
            </div>
        );
    }
}

export default Card;