import React, { Component } from 'react';
import Cards from './Cards.js';
import Stat from './Stat.js';
import Since from './Since.js';
import Title from './Title.js';
import Header from './Header.js';
import Footer from './Footer.js';
import * as StatHelpers from './StatHelpers.js';

let {year, quarter, month, months, day, statNum} = StatHelpers;

let stats = [
    new Stat(year(27000000000), "/reddit/votes.svg", "https://redditblog.com/2018/12/04/reddit-year-in-review-2018/", function(num){
        return <>{statNum(num)} votes</>;
    }),
    new Stat(year(1200000000), "/uber/car.svg", "https://redditblog.com/2018/12/04/reddit-year-in-review-2018/", function(num){
        return <>{statNum(num)} comments</>;
    }),
    new Stat(year(153000000), "/uber/car.svg", "https://redditblog.com/2018/12/04/reddit-year-in-review-2018/", function(num){
        return <>{statNum(num)} posts</>;
    }),
    new Stat(month(333000000), "/uber/car.svg", "https://redditblog.com/2018/11/13/holiday-on-reddit/", function(num){
        return <>{statNum(num)} visitors</>;
    }),
    new Stat(month(18000000000), "/uber/car.svg", "https://redditblog.com/2018/11/13/holiday-on-reddit/", function(num){
        return <>{statNum(num)} pageviews</>;
    }),
    new Stat(month(1400000000), "/uber/car.svg", "https://redditblog.com/2018/12/04/reddit-year-in-review-2018/", function(num){
        return <>{statNum(num)} video views</>;
    }),
    new Stat(year(119000000), "/uber/car.svg", "https://www.emarketer.com/content/reddit-to-cross-100-million-in-ad-revenues-in-2019", function(num){
        return <>${statNum(num)} in ad revenue</>;
    }),
    /*new Stat(year(1900000), "/uber/car.svg", "http://gold.reddit-stream.com/gold/table", function(num){
        return <>${statNum(num)} made from Reddit Gold</>;
    }),*/
    new Stat(day(400000), "/uber/car.svg", "https://redditblog.com/2018/12/04/reddit-year-in-review-2018/", function(num){
        return <>{statNum(num)} hours of video watched</>;
    }),
    /*new Stat(month(14000000), "/uber/car.svg", "https://redditblog.com/2019/01/23/health-fitness-on-reddit/", function(num){
        return <>{statNum(num)} visitors health & fitness communities</>;
    }),
    new Stat(month(12000000), "/uber/car.svg", "https://redditblog.com/2019/02/07/style-fashion-on-reddit/", function(num){
        return <>{statNum(num)} visitors to style & fashion communities</>;
    }),
    new Stat(month(27000000), "/uber/car.svg", "https://redditblog.com/2019/01/08/technology-on-reddit/", function(num){
        return <>{statNum(num)} visitors to technology communities</>;
    }),
    new Stat(month(20000000), "/uber/car.svg", "https://redditblog.com/2018/10/17/politics-on-reddit/", function(num){
        return <>{statNum(num)} visitors to political communities</>;
    }),
    new Stat(month(63000000), "/uber/car.svg", "https://redditblog.com/2018/09/27/gaming-on-reddit/", function(num){
        return <>{statNum(num)} visitors to gaming communities</>;
    }),
    new Stat(month(7300000), "/uber/car.svg", "https://redditblog.com/2018/09/07/football-on-reddit/", function(num){
        return <>{statNum(num)} visitors to football communities</>;
    }),
    new Stat(month(5100000), "/uber/car.svg", "https://redditblog.com/2018/09/28/22322/", function(num){
        return <>{statNum(num)} visitors to basketball communities</>;
    }),
    new Stat(month(11000000), "/uber/car.svg", "https://redditblog.com/2018/09/28/22322/", function(num){
        return <>{statNum(num)} visitors to soccer communities</>;
    }),
    new Stat(month(2700000), "/uber/car.svg", "https://redditblog.com/2018/08/29/beauty-on-reddit/", function(num){
        return <>{statNum(num)} visitors to beauty communities</>;
    }),*/
    new Stat(month(2920000), "/uber/car.svg", "https://redditblog.com/2019/01/08/technology-on-reddit/", function(num){
        return <>{statNum(num)} comments in tech communities</>;
    }),
    new Stat(month(7300000), "/uber/car.svg", "https://redditblog.com/2018/09/27/gaming-on-reddit/", function(num){
        return <>{statNum(num)} comments in gaming communities</>;
    }),
    new Stat(month(6000000), "/uber/car.svg", "https://redditblog.com/2018/10/17/politics-on-reddit/", function(num){
        return <>{statNum(num)} comments in political communities</>;
    }),
    new Stat(month(28000000), "/uber/car.svg", "https://redditblog.com/2018/10/17/politics-on-reddit/", function(num){
        return <>{statNum(num)} downvotes in political communities</>;
    }),
];

class Reddit extends Component {

    componentDidMount(){
        
    }

    render() {
        return (
            <>
                <Header/>
                <div className="body-container">
                    <Title icon="/reddit/reddit.svg" title="Reddit - Every Second"/>
                        <Since color="#FF4500"/>
                        <Cards cols={2} stats={stats}></Cards>
                </div>
                <Footer/>
            </>
        );
    }
}

export default Reddit;