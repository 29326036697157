import React, { Component } from 'react';
import Cards from './Cards.js';
import Stat from './Stat.js';
import Since from './Since.js';
import Title from './Title.js';
import Header from './Header.js';
import Footer from './Footer.js';
import MetaTags from './MetaTags.js';
import * as StatHelpers from './StatHelpers.js';

let {year, quarter, month, months, day, statNum} = StatHelpers;

let stats = [
    new Stat(day(17000000), "/uber/car.svg", "https://techcrunch.com/2019/06/05/uber-is-finally-trading-above-its-ipo-price/", function(num){
        return <>{statNum(num)} trips completed</>;
    }),
    new Stat(year(26000000000), "/uber/map.svg", "https://www.sec.gov/Archives/edgar/data/1543151/000119312519103850/d647752ds1.htm", function(num){
        return <>{statNum(num)} miles driven</>;
    }),
    new Stat(months(1200000000, 18), "/uber/tips.svg", "https://www.sec.gov/Archives/edgar/data/1543151/000119312519103850/d647752ds1.htm", function(num){
        return <>${statNum(num)} in tips</>;
    }),
    new Stat(day(450000), "/uber/statue-of-liberty.svg", "https://www.businessinsider.com/uber-earnings-q1-2019-losses-at-least-1-billion-2019-4", function(num){
        return <>{statNum(num)} trips completed in NYC</>;
    }),
    new Stat(quarter(155000000), "/uber/app.svg", "https://sensortower.com/blog/top-ridesharing-apps-worldwide-2018", function(num){
        return <>{statNum(num)} app downloads</>;
    }),
    new Stat(quarter(15000000), "/uber/delivery-guy.svg", "https://www.sec.gov/Archives/edgar/data/1543151/000119312519103850/d647752ds1.htm", function(num){
        return <>{statNum(num)} Uber Eats deliveries</>;
    }),
    new Stat(quarter(125000000), "/uber/truck.svg", "https://www.sec.gov/Archives/edgar/data/1543151/000119312519103850/d647752ds1.htm", function(num){
        return <>${statNum(num)} made from Uber Freight</>;
    }),
    new Stat(quarter(1000000000), "/uber/graph.svg", "https://www.businessinsider.com/uber-earnings-q1-2019-losses-at-least-1-billion-2019-4", function(num){
        return <>${statNum(num)} net loss</>;
    })
];

class DisneyWorld extends Component {

    componentDidMount(){
        
    }

    render() {
        return (
            <>
                <MetaTags 
                    title="Uber - Every Second"
                    description="See how many Uber rides are given every second. Plus 7 other interesting Uber stats!"
                    socialDesc="How many Uber rides are given every second?"
                    favicon="/uber/car.png"
                    canonical="/uber"
                />
                <Header/>
                <div className="body-container">
                    <Title icon="/uber/car.svg" title="Uber - Every Second"/>
                        <Since color="#333333"/>
                        <Cards cols={2} stats={stats}></Cards>
                </div>
                <Footer/>
            </>
        );
    }
}

export default DisneyWorld;