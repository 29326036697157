import React, { Component } from 'react';
import Header from './Header.js';
import Footer from './Footer.js';
import Title from './Title.js';
import styled from 'styled-components';

const DiseaseControlWrapper = styled.div`
    max-width: 100%;
    padding: 20px;
    background-color: white;
`

const DiseaseControlGrid = styled.div`
    display: grid;
    max-width: 450px;
    height: 450px;
    grid-template-columns: repeat(25, 1fr);
    grid-gap: 1px 1px;
`

const Block = styled.div`
    width: 100%;
    height: 100%;
    cursor: pointer;
`

const Human = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => props.color};
`

class DiseaseControl extends Component {
    constructor(props) {
        super(props);
        this.state = {blocks: Array(625).fill(0), cols: 25, infected: 0, infectionRate: .25, cureRate: .2, immuneRate: .1, deathRate: .2}
        this.getCords = this.getCords.bind(this);
        this.infect = this.infect.bind(this);
        this.next = this.next.bind(this);
        this.isInfected = this.isInfected.bind(this);
    }

    componentDidMount(){
        this.genBlocks();
        setInterval(this.next, 125);
    }

    infect(i){
        let {blocks} = this.state;
        if(blocks[i] == 0){
            blocks[i] = 1;
            this.setState({blocks: blocks});
        }
    }

    genBlocks(){
        let {blocks, cols} = this.state;
        for(let x = 0; x < cols * cols; x++){
            if(this.isImmune()){
                blocks[x] = 2;
            }else{
                blocks[x] = 0;
            }
        }

        this.setState({blocks: blocks});
    }

    next(){
        let {blocks} = this.state;
        let newBlocks = blocks.slice();
        for(let x = 0; x < blocks.length; x++){
            if(blocks[x] == 1){
                newBlocks = this.checkNeighbors(newBlocks, x);
            }
        }
        this.setState({blocks: newBlocks});
    }

    isImmune(){
        let {immuneRate} = this.state;
        if(Math.random() < immuneRate){
            return true;
        }
        return false;
    }

    isInfected(){
        let {infectionRate} = this.state;
        if(Math.random() < infectionRate){
            return true;
        }
        return false;
    }

    isCured(){
        let {cureRate} = this.state;
        if(Math.random() < cureRate){
            return true;
        }
        return false;
    }

    isDead(){
        let {deathRate} = this.state;
        if(Math.random() < deathRate){
            return true;
        }
        return false;
    }

    checkNeighbors(blocks, index){
        let cords = this.getCords(index);
        let row = cords[0];
        let col = cords[1];

        let left = this.getBlock(row, col - 1);
        let right = this.getBlock(row, col + 1);
        let top = this.getBlock(row - 1, col);
        let bottom = this.getBlock(row + 1, col);

        let adj = [left, right, top, bottom];

        for(let x = 0; x < adj.length; x++){
            if(adj[x] != -1 && adj[x]){
                if(blocks[adj[x]] == 0 && this.isInfected()){
                    blocks[adj[x]] = 1;
                }
            }
        }

        if(this.isCured()){
            blocks[index] = 0;
        }

        if(this.isDead()){
            blocks[index] = 3;
        }

        return blocks;
    }

    getBlock(row, col){
        let {cols} = this.state;
        if(row < 0 || row >= cols || col < 0 || col >= cols){
            return -1;
        }
        let index = (cols * row) + col;
        return index;
    }

    getCords(i){
        let {cols} = this.state;
        let row = Math.floor(i/cols);
        let col = i % cols;
        console.log("row: " + row + " col: " + col);
        return [row, col];
    }

    getColor(code){
        if(code == 0){
            return "#c8d6e5";
        }else if(code == 1){
            return "#ff6b6b";
        }else if(code == 2){
            return "#74b9ff";
        }else if(code == 3){
            return "#2d3436";
        }
    }
    

    render() {
        let {blocks} = this.state;
        console.log(blocks);
        return (
            <>
                <Header/>
                <div className="body-container">
                    <Title icon="/earth/earth.svg" title="Disease Control"/>
                    <DiseaseControlWrapper>
                        <DiseaseControlGrid>
                            {blocks.map(function(block, i){
                                let color = this.getColor(block);
                                return <Block onClick={() => this.infect(i)}>
                                    <Human color={color}/>
                                </Block>
                            }.bind(this))}
                        </DiseaseControlGrid>
                    </DiseaseControlWrapper>
                </div>
                <Footer/>
            </>
        );
    }
}

export default DiseaseControl;