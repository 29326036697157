import React, { Component } from 'react';
import styled, {keyframes} from 'styled-components';
import Title from './Title.js';
import MetaTags from './MetaTags.js';
import Header from './Header.js';
import Footer from './Footer.js';

const FrameRateContainer = styled.div`
    background: white;
    max-width: 900px;
    padding: 30px;
`

const FPSMove = (width) => keyframes`
    from {
        transform: translateX(0px);
    }
    to {
        transform: translateX(${width}px);
    }
`

const FPSTitle = styled.div`
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 500;
`

const FPSCircle = styled.div`
    background-color: black;
    width: 45px;
    height: 45px;
    border-radius: 40px;
    animation: ${props => FPSMove(props.width)} ${props => props.multiplier}s infinite steps(${props => props.multiplier * props.steps}) alternate;
`

class FramesPerSecond extends Component {
    constructor(props){
        super(props);
        let width = Math.min(window.innerWidth - 100, 800);
        let multiplier = this.multiplier(width);
        this.state = {width: width, multiplier: multiplier};

        this.calc = this.calc.bind(this);
    }

    componentDidMount(){
        window.addEventListener('resize', this.calc);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.calc);
    }

    calc(){
        let newWidth = Math.min(window.innerWidth - 100, 800);
        let newMultiplier = this.multiplier(newWidth);
        this.setState({width: newWidth, multiplier: newMultiplier})
    }

    multiplier(width){
        if(width > 600){
            return 3;
        }else if(width > 400){
            return 2;
        }else{
            return 1;
        }
    }

    render() {
        let {width, multiplier} = this.state;
        return (
            <>
                <MetaTags 
                    title="Frames Per Second Comparison"
                    description="Compare different frame rates and see what they look like. Compares 10fps, 15fps, 24fps, 30fps, 60fps and 100fps."
                    socialDesc="See what different frame rates look like"
                    favicon="/frames-per-second/circle.png"
                    canonical="/frames-per-second"
                />
                <Header/>
                <div className="body-container">
                    <Title title = "Frames Per Second Comparison"/>
                    <FrameRateContainer>
                            <FPSTitle>10 FPS</FPSTitle>
                            <FPSCircle multiplier={multiplier} width={width} steps={10}/>
                            <FPSTitle>15 FPS</FPSTitle>
                            <FPSCircle multiplier={multiplier} width={width} steps={15}/>
                            <FPSTitle>24 FPS</FPSTitle>
                            <FPSCircle multiplier={multiplier} width={width} steps={24}/>
                            <FPSTitle>30 FPS</FPSTitle>
                            <FPSCircle multiplier={multiplier} width={width} steps={30}/>
                            <FPSTitle>40 FPS</FPSTitle>
                            <FPSCircle multiplier={multiplier} width={width} steps={40}/>
                            <FPSTitle>50 FPS</FPSTitle>
                            <FPSCircle multiplier={multiplier} width={width} steps={50}/>
                            <FPSTitle>60 FPS</FPSTitle>
                            <FPSCircle multiplier={multiplier} width={width} steps={60}/>
                            <FPSTitle>100 FPS</FPSTitle>
                            <FPSCircle multiplier={multiplier} width={width} steps={100}/>
                    </FrameRateContainer>
                </div>
                <Footer/>
            </>
        );
    }
}

export default FramesPerSecond;