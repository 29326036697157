import React, { Component } from 'react';
import Pages from './Pages.js';
import LinkBox from './LinkBox.js';
import Ad from './Ad.js';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {pages: []};
    }
    

    componentDidMount(){
        let pages = Pages;
        let pathName = this.stripTrailingSlash(window.location.pathname);
    
        let filteredPages = pages.filter(page => page.link !== pathName);
        let randomItems = [];

        for(let x = 0; x < 2; x++){
            randomItems.push(
                filteredPages.splice(
                    Math.floor(filteredPages.length * Math.random()), 1
                )[0]
            );
        }

        console.log(randomItems);

        this.setState({pages: randomItems});
    }

    stripTrailingSlash(str) {
        if(str.substr(-1) === '/') {
            return str.substr(0, str.length - 1);
        }
        return str;
    }

    render() {
        let {pages} = this.state;
        return (
            <>
                <Ad/>
                <div className="footer">
                    <div className="footer-mssg">You may also like</div>
                    <div className="footer-links">
                        {pages.map(function(page){
                            return <LinkBox icon={page.icon} bgColor="#f1f2f6" color="#333333" page={page.link} text={page.title}/>
                        })}
                    </div>
                    <div className="footer-credit">Made with <img src="/general/like.svg"/> by <a target="_blank" href="https://twitter.com/nealagarwal">Neal Agarwal</a></div>
                </div>
            </>
        );
    }
}

export default Footer;