import React, { Component } from 'react';
import Cards from './Cards.js';
import Stat from './Stat.js';
import Since from './Since.js';
import Title from './Title.js';
import Header from './Header.js';
import Footer from './Footer.js';
import MetaTags from './MetaTags.js';
import * as StatHelpers from './StatHelpers.js';

let {year, quarter, month, day, statNum} = StatHelpers;

let stats = [
    new Stat(year(217000000), "/apple/iphone.svg", "https://www.statista.com/statistics/276306/global-apple-iphone-sales-since-fiscal-year-2007/", function(num){
        return <>{statNum(num)} iPhones sold</>;
    }),
    new Stat(year(43600000), "/apple/ipad.svg", "https://www.macstories.net/news/apple-q4-2018-results-533-billion-revenue-413-million-iphones-116-million-ipads-sold/", function(num){
        return <>{statNum(num)} iPads sold</>;
    }),
    new Stat(year(18200000), "/apple/mac.svg", "https://www.macstories.net/news/apple-q4-2018-results-533-billion-revenue-413-million-iphones-116-million-ipads-sold/", function(num){
        return <>{statNum(num)} Macs sold</>;
    }),
    new Stat(year(35000000), "/apple/airpods.svg", "https://appleinsider.com/articles/19/03/15/apple-sold-35-million-airpods-in-2018-currently-most-popular-hearable-brand", function(num){
        return <>{statNum(num)} AirPods sold</>;
    }),
    new Stat(year(22500000), "/apple/apple-watch.svg", "https://9to5mac.com/2019/02/27/apple-watch-sales-2018/", function(num){
        return <>{statNum(num)} Apple Watches sold</>;
    }),
    new Stat(quarter(1600000), "/apple/homepod.svg", "https://www.idropnews.com/news/homepod-sales-grew-45-percent-over-holiday-quarter-but-actually-lost-market-share/97172/", function(num){
        return <>{statNum(num)} HomePods sold</>;
    }),
    new Stat(year(265595000000), "/apple/money.svg", "https://www.macrotrends.net/stocks/charts/AAPL/apple/revenue", function(num){
        return <>{statNum("$" + num)} in revenue</>;
    }),
    new Stat(year(59531000000), "/apple/money.svg", "https://www.macrotrends.net/stocks/charts/AAPL/apple/net-income", function(num){
        return <>{statNum("$" + num)} in net income</>;
    }),
    new Stat(year(1800000000), "/apple/megaphone.svg", "https://9to5mac.com/2016/11/24/apple-aapl-ad-spend-2016/", function(num){
        return <>{statNum("$" + num)} spent on marketing</>;
    }),
    new Stat(year(30000000000), "/apple/app-store.svg", "https://www.businessofapps.com/data/app-statistics/#1", function(num){
        return <>{statNum(num)} app downloads</>;
    }),
    new Stat(year(8900000000), "/apple/mobile-game.svg", "https://www.businessofapps.com/data/app-statistics/#2", function(num){
        return <>{statNum(num)} game downloads</>;
    }),
    new Stat(year(34000000000), "/apple/code.svg", "https://fortune.com/2019/01/28/apple-app-store-developer-earnings-2018/", function(num){
        return <>${statNum(num)} paid to developers</>;
    }),
    new Stat(month(10000000000), "/apple/siri.svg", "https://www.cnbc.com/2019/02/28/siri-tips-and-tricks-for-iphone.html", function(num){
        return <>{statNum(num)} Siri requests</>;
    }),
    new Stat(year(5000000000), "/apple/apple-maps.svg", "https://9to5mac.com/2015/12/07/apple-maps-usage-numbers/", function(num){
        return <>{statNum(num)} Apple Maps queries</>;
    }),
    new Stat(year(10000000000), "/apple/apple-pay.svg", "https://www.textrequest.com/blog/how-many-texts-people-send-per-day/", function(num){
        return <>{statNum(num)} Apple Pay transactions</>;
    }),
    new Stat(year(500000000), "/apple/store.svg", "https://www.textrequest.com/blog/how-many-texts-people-send-per-day/", function(num){
        return <>{statNum(num)} visits to an Apple Store</>;
    }),
    new Stat(day(15000000), "/apple/facetime.svg", "https://www.macrumors.com/2014/02/28/apple-40-billion-imessages/", function(num){
        return <>{statNum(num)} FaceTime Calls</>;
    }),
    new Stat(200000, "/apple/imessage.svg", "https://www.textrequest.com/blog/how-many-texts-people-send-per-day/", function(num){
        return <>{statNum(num)} iMessages sent</>;
    }),
];

class Apple extends Component {
    render() {
        return (
            <>
                <MetaTags 
                    title="Apple - Every Second"
                    description="See how many iPhones, iPads, and Macs Apple sells every second! 10+ interesting Apple stats."
                    socialDesc="How much is Apple selling every second?"
                    favicon="/apple/iphone.png"
                    canonical="/apple"
                />
                <Header/>
                <div className="body-container">
                    <Title icon="/apple/iphone.svg" title="Apple - Every Second"/>
                    <Since/>
                    <Cards cols={2} stats={stats}></Cards>
                </div>
                <Footer/>
            </>
        );
    }
}

export default Apple;