import React, { Component } from 'react';
import Title from './Title.js';
import Header from './Header.js';
import Footer from './Footer.js';
import MetaTags from './MetaTags.js';
import styled, {keyframes} from 'styled-components';

const Heartbeat = keyframes`
    0%{
      transform: scale(1);
    }
    50%{
      transform: scale(.6);
    }
    100%{
      transform: scale(1);
    }
`

const HeartbeatHamster = keyframes`
    0%{
      transform: scale(1);
    }
    50%{
      transform: scale(.8);
    }
    100%{
      transform: scale(1);
    }
`

const HeartbeatFast = keyframes`
    0%{
      transform: scale(1);
    }
    50%{
      transform: scale(.93);
    }
    100%{
      transform: scale(1);
    }
`

const AnimalHeart = styled.img`
    max-width: 170px;
    animation-name: ${props => props.animation};
    animation-iteration-count: infinite;
    animation-duration: ${props => props.duration}s;
`

const AnimalName = styled.div`
    font-size: 26px;
    font-weight: 500;
    margin-top: 15px;
`

const AnimalBPM = styled.div`
    margin-top: 3px;
    font-size: 18px;
`

class AnimalHeartbeats extends Component {
    render() {
        return (
            <>
                <MetaTags 
                    title="Animal Heartbeats"
                    description="Visualize the heartbeats of different animals, including whales, elephants and dogs!"
                    socialDesc="Visualize the heartbeats of other animals"
                    favicon="/animal-heartbeats/heart.png"
                    canonical="/animal-heartbeats"
                />
                <Header/>
                <div className="body-container">
                    <Title icon="/animal-heartbeats/heart.svg" title="Animal Heartbeats"/>
                    <div className="cards">
                        <div className="card">
                            <AnimalHeart animation={Heartbeat} duration={7.5} src="/animal-heartbeats/heart.svg"/>
                            <AnimalName>Blue Whales</AnimalName>
                            <AnimalBPM>8 bpm</AnimalBPM>
                            <a className="card-source" target="_blank" href="https://www.whalefacts.org/blue-whale-heart/">Source</a>
                        </div>
                        <div className="card">
                        <AnimalHeart animation={Heartbeat} duration={2} src="/animal-heartbeats/heart.svg"/>
                            <AnimalName>Elephants</AnimalName>
                            <AnimalBPM>30 bpm</AnimalBPM>
                            <a className="card-source" target="_blank" href="http://wildpro.twycrosszoo.org/s/0MProboscidae/Elephantidae/Loxodonta/Loxodonta_africana/10LoxAfrDetPhy.htm#Pulse">Source</a>
                        </div>
                        <div className="card">
                        <AnimalHeart animation={Heartbeat} duration={1.6} src="/animal-heartbeats/heart.svg"/>
                            <AnimalName>Horses</AnimalName>
                            <AnimalBPM>36 bpm</AnimalBPM>
                            <a className="card-source" target="_blank" href="https://thehorse.com/125642/taking-a-horses-heart-rate/">Source</a>
                        </div>
                        <div className="card">
                        <AnimalHeart animation={Heartbeat} duration={.8} src="/animal-heartbeats/heart.svg"/>
                            <AnimalName>Humans</AnimalName>
                            <AnimalBPM>75 bpm</AnimalBPM>
                            <a className="card-source" target="_blank" href="https://thehorse.com/125642/taking-a-horses-heart-rate/">Source</a>
                        </div>
                        <div className="card">
                        <AnimalHeart animation={Heartbeat} duration={.75} src="/animal-heartbeats/heart.svg"/>
                            <AnimalName>Big Dogs</AnimalName>
                            <AnimalBPM>80 bpm</AnimalBPM>
                            <a className="card-source" target="_blank" href="http://www.vetstreet.com/dr-marty-becker/check-your-dogs-vital-signs-at-home">Source</a>
                        </div>
                        <div className="card">
                        <AnimalHeart animation={Heartbeat} duration={.5} src="/animal-heartbeats/heart.svg"/>
                            <AnimalName>Small Dogs</AnimalName>
                            <AnimalBPM>120 bpm</AnimalBPM>
                            <a className="card-source" target="_blank" href="http://www.vetstreet.com/dr-marty-becker/check-your-dogs-vital-signs-at-home">Source</a>
                        </div>
                        <div className="card">
                        <AnimalHeart animation={Heartbeat} duration={.4} src="/animal-heartbeats/heart.svg"/>
                            <AnimalName>Giraffes</AnimalName>
                            <AnimalBPM>150 bpm</AnimalBPM>
                            <a className="card-source" target="_blank" href="https://giraffeproject.weebly.com/anatomy--status.html">Source</a>
                        </div>
                        <div className="card">
                        <AnimalHeart animation={Heartbeat} duration={.375} src="/animal-heartbeats/heart.svg"/>
                            <AnimalName>Cats</AnimalName>
                            <AnimalBPM>160 bpm</AnimalBPM>
                            <a className="card-source" target="_blank" href="http://www.vetstreet.com/dr-marty-becker/check-your-cats-vital-signs-at-home">Source</a>
                        </div>
                        <div className="card">
                            <AnimalHeart animation={HeartbeatHamster} duration={.15} src="/animal-heartbeats/heart.svg"/>
                            <AnimalName>Hamsters</AnimalName>
                            <AnimalBPM>400 bpm</AnimalBPM>
                            <a className="card-source" target="_blank" href="http://www.petwebsite.com/hamsters/hamster_vital_signs.asp">Source</a>
                        </div>
                        <div className="card">
                            <AnimalHeart animation={HeartbeatFast} duration={.05} src="/animal-heartbeats/heart.svg"/>
                            <AnimalName>Hummingbirds</AnimalName>
                            <AnimalBPM>1200 bpm</AnimalBPM>
                            <a className="card-source" target="_blank" href="https://www.thespruce.com/fun-facts-about-hummingbirds-387106">Source</a>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        );
    }
}

export default AnimalHeartbeats;