let pages = [];

function Page(title, icon, link){
    this.title = title;
    this.icon = icon;
    this.link = link;
    pages.push(this);
}

new Page("Apple", "/apple/iphone.svg", "/apple");
new Page("The Internet", "/the-internet/the-internet.svg", "/the-internet");
new Page("McDonalds", "/mcdonalds/burger.svg", "/mcdonalds");
new Page("Messenger", "/messenger/chat.svg", "/messenger");
new Page("Uber", "/uber/car.svg", "/uber");
new Page("Disney World", "/disney-world/castle.svg", "/disney-world");
new Page("Animal Heartbeats", "/animal-heartbeats/heart.svg", "/animal-heartbeats");
new Page("Instagram", "/instagram/boy.svg", "/instagram");
new Page("Frames Per Second", "/frames-per-second/circle.svg", "/frames-per-second");
new Page("Spotify Charts", "/spotify-charts/boom-box.svg", "/spotify-charts");
new Page("Your Body", "/your-body/heart.svg", "/your-body");
new Page("Earth", "/earth/earth.svg", "/earth");

export default pages;