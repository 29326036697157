import React, { Component } from 'react';
import {Link} from "react-router-dom";

class LinkBox extends Component {
    render() {
        let {icon, text, page, bgColor, color} = this.props;
        return (
            <Link style={{backgroundColor: bgColor, color: color}} to={page} className="link-box">
                <img className="link-box-icon" src={icon}/>
                <div className="link-box-text">{text}</div>
            </Link>
        );
    }
}

export default LinkBox;