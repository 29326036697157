import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class MetaTags extends Component {
    render() {
        let {title, desc, socialDesc, canonical, image, favicon} = this.props;

        let baseUrl = "https://everysecond.io";

        return (
            <Helmet>
                <title>{title}</title>
                <meta name="title" content={title}/>
                <meta name="description" content={desc}/>
                <link rel="canonical" href={baseUrl + canonical}/>

                <link rel="icon" href={favicon}/>

                <meta property="og:type" content="website"/>
                <meta property="og:url" content={baseUrl + canonical}/>
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={socialDesc}/>
                <meta property="og:image" content={image}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content={baseUrl + canonical}/>
                <meta property="twitter:title" content={title}/>
                <meta property="twitter:description" content={socialDesc}/>
                <meta property="twitter:image" content={image}/>
            </Helmet>
        );
    }
}

export default MetaTags;