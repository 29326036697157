import React, { Component } from 'react';
import Cards from './Cards.js';
import Stat from './Stat.js';
import Since from './Since.js';
import Title from './Title.js';
import Header from './Header.js';
import Footer from './Footer.js';
import MetaTags from './MetaTags.js';
import * as StatHelpers from './StatHelpers.js';

let {year, quarter, month, day, statNum} = StatHelpers;

let stats = [
    new Stat(day(100000000), "/instagram/photo.svg", "https://www.omnicoreagency.com/instagram-statistics/", function(num){
        return <>{statNum(num)} posts</>;
    }),
    new Stat(day(4200000000), "/instagram/love.svg", "https://www.omnicoreagency.com/instagram-statistics/", function(num){
        return <>{statNum(num)} likes</>;
    }),
    new Stat(day(500000000), "/instagram/book.svg", "https://techcrunch.com/2019/01/30/instagram-stories-500-million/", function(num){
        return <>{statNum(num)} Instagram Story users</>;
    }),
    new Stat(year(9000000000), "/instagram/digital-advertising.svg", "https://marketrealist.com/2019/01/why-analysts-are-pessimistic-about-fords-q4-2018-results/", function(num){
        return <>${statNum(num)} in ad revenue</>;
    }),
    new Stat(year(200000000), "/instagram/boy.svg", "https://www.statista.com/statistics/253577/number-of-monthly-active-instagram-users/", function(num){
        return <>{statNum(num)} new users</>;
    }),
    new Stat(year(14000000000), "/instagram/heart.svg", "https://instagram-press.com/blog/2018/12/12/instagram-year-in-review-2018/", function(num){
        return <>{statNum(num)} heart emojis used</>;
    }),
];

class Instagram extends Component {
    render() {
        return (
            <>
                <MetaTags 
                    title="Instagram - Every Second"
                    description="See how many photos are uploaded to Instagram every second! Plus other interesting Instagram stats."
                    socialDesc="How many Instagram posts are there every second?"
                    canonical="/instagram"
                />
                <Header/>
                <div className="body-container">
                    <Title icon="/instagram/boy.svg" title="Instagram - Every Second"/>
                    <Since/>
                    <Cards cols={2} stats={stats}></Cards>
                </div>
                <Footer/>
            </>
        );
    }
}

export default Instagram;