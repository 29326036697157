import React, { Component } from 'react';
import Cards from './Cards.js';
import Stat from './Stat.js';
import Since from './Since.js';
import Title from './Title.js';
import Header from './Header.js';
import Footer from './Footer.js';
import MetaTags from './MetaTags.js';
import * as StatHelpers from './StatHelpers.js';

let {year, quarter, month, day, minute, statNum} = StatHelpers;

let stats = [
    new Stat(year(360000000), "/the-internet/group.svg", "https://wearesocial.com/blog/2018/01/global-digital-report-2018", function(num){
        return <>{statNum(num)} new internet users</>;
    }),
    new Stat(year(280000000), "/the-internet/message.svg", "https://wearesocial.com/blog/2018/01/global-digital-report-2018", function(num){
        return <>{statNum(num)} new social media users</>;
    }),
    new Stat(year(2000000000000), "/the-internet/search.svg", "https://seotribunal.com/blog/how-many-google-searches-per-day/", function(num){
        return <>{statNum(num)} searches</>;
    }),
    new Stat(quarter(333800000), "/the-internet/domain-registration.svg", "https://blog.verisign.com/domain-names/verisign-q1-2018-domain-name-industry-brief-internet-grows-to-333-8-million-domain-name-registrations-in-the-first-quarter-of-2018/", function(num){
        return <>{statNum(num)} domain registrations</>;
    }),
    new Stat(minute(3138420), "/the-internet/database.svg", "https://www.cisco.com/c/en/us/solutions/service-provider/visual-networking-index-vni/index.html#mobile-forecast", function(num){
        return <>{statNum(num)} GB of traffic</>;
    }),
    new Stat(day(2500000000), "/the-internet/server.svg", "https://www.forbes.com/sites/bernardmarr/2018/05/21/how-much-data-do-we-create-every-day-the-mind-blowing-stats-everyone-should-read/#3fafa1e60ba9", function(num){
        return <>{statNum(num)} GB of data created</>;
    }),
    new Stat(minute(73249), "/the-internet/deal.svg", "https://www.domo.com/assets/downloads/18_domo_data-never-sleeps-6+verticals.pdf", function(num){
        return <>{statNum(num)} online transactions</>;
    }),
    new Stat(year(4748000000000), "/the-internet/coin.svg", "https://www.statista.com/statistics/379046/worldwide-retail-e-commerce-sales/", function(num){
        return <>${statNum(num)} in e-commerce sales</>;
    }),
    new Stat(year(70000000000), "/the-internet/light-bulb.svg", "https://www.forbes.com/sites/bernardmarr/2018/05/21/how-much-data-do-we-create-every-day-the-mind-blowing-stats-everyone-should-read/#3fafa1e60ba9", function(num){
        return <>{statNum(num)} kWh of energy used</>;
    }),
    new Stat(day(4000000), "/the-internet/article.svg", "https://seotribunal.com/blog/how-many-google-searches-per-day/", function(num){
        return <>{statNum(num)} blog posts published</>;
    }),
    new Stat(minute(4756), "/the-internet/coffee-machine.svg", "https://www.domo.com/assets/downloads/18_domo_data-never-sleeps-6+verticals.pdf", function(num){
        return <>{statNum(num)} IoT connections made</>;
    }),
    new Stat(day(246500000000), "/the-internet/email.svg", "https://www.campaignmonitor.com/blog/email-marketing/2019/05/shocking-truth-about-how-many-emails-sent/", function(num){
        return <>{statNum(num)} emails sent</>;
    }),
];

class Internet extends Component {
    render() {
        return (
            <>
                <MetaTags 
                    title="The Internet - Every Second"
                    description="See all the things happening on the Internet every second. Includes the number of emails, searches and more!"
                    socialDesc="What happens every second on the internet?"
                    favicon="/the-internet/the-internet.png"
                    canonical="/the-internet"
                />
                <Header/>
                <div className="body-container">
                    <Title icon="/the-internet/the-internet.svg" title="The Internet - Every Second"/>
                    <Since color="#4895FF"/>
                    <Cards cols={2} stats={stats}></Cards>
                </div>
                <Footer/>
            </>
        );
    }
}

export default Internet;