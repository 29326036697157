import React, { Component } from 'react';
import Cards from './Cards.js';
import Stat from './Stat.js';
import Since from './Since.js';
import Title from './Title.js';
import Header from './Header.js';
import Footer from './Footer.js';
import MetaTags from './MetaTags.js';
import * as StatHelpers from './StatHelpers.js';

let {year, quarter, month, day, statNum, minute} = StatHelpers;

let stats = [
    new Stat(day(50000000000), "/messenger/chat.svg", "https://techcrunch.com/2019/04/30/facebook-messenger-desktop-app/", function(num){
        return <>{statNum(num)} messages sent</>;
    }),
    new Stat(month(17000000000), "/messenger/photo.svg", "https://techcrunch.com/2019/04/30/facebook-messenger-desktop-app/", function(num){
        return <>{statNum(num)} photos shared</>;
    }),
    new Stat(year(17000000000), "/messenger/video-call.svg", "https://newsroom.fb.com/news/2017/12/messengers-2017-year-in-review/", function(num){
        return <>{statNum(num)} video chats</>;
    }),
    new Stat(quarter(31300000), "/messenger/download.svg", "https://www.businessinsider.com/most-downloaded-iphone-apps-worldwide-tik-tok-q1-2018-5#5-instagram-6", function(num){
        return <>{statNum(num)} downloads on iOS</>;
    }),
    new Stat(day(5000000000), "/messenger/in-love.svg", "https://newsroom.fb.com/news/2017/12/messengers-2017-year-in-review/", function(num){
        return <>{statNum(num)} emojis sent</>;
    }),
    new Stat(year(18000000000), "/messenger/heart.svg", "https://newsroom.fb.com/news/2017/12/messengers-2017-year-in-review/", function(num){
        return <>{statNum(num)} gifs sent</>;
    }),
    new Stat(day(260000000), "/messenger/chat.svg", "https://newsroom.fb.com/news/2017/12/messengers-2017-year-in-review/", function(num){
        return <>{statNum(num)} new conversations</>;
    }),
    new Stat(day(2500000), "/messenger/group-chat.svg", "https://newsroom.fb.com/news/2017/12/messengers-2017-year-in-review/", function(num){
        return <>{statNum(num)} new group chats</>;
    }),
    new Stat(year(11000000000), "/messenger/like.svg", "https://newsroom.fb.com/news/2017/12/messengers-2017-year-in-review/", function(num){
        return <>{statNum(num)} reactions shared</>;
    }),
    new Stat(year(11000000000), "/messenger/unicorn.svg", "https://venturebeat.com/2016/07/20/facebook-messenger-hits-1-billion-monthly-active-users-now-powers-10-of-all-voip-calls/", function(num){
        return <>{statNum(num)} stickers used</>;
    }),
    new Stat(month(20000000000), "/messenger/shopping.svg", "https://developers.facebook.com/blog/post/v2/2019/04/30/messenger-f8-2019/", function(num){
        return <>{statNum(num)} messages to businesses</>;
    }),
    new Stat(year(4200000000), "/messenger/game.svg", "https://newsroom.fb.com/news/2017/12/celebrating-one-year-of-games-on-messenger/", function(num){
        return <>{statNum(num)} games played</>;
    }),
];

class Apple extends Component {
    render() {
        return (
            <>
                <MetaTags 
                    title="Messenger - Every Second"
                    description="See how many messages and photos are sent on Facebook Messenger every second."
                    socialDesc="How many messages are sent on Facebook Messenger every second?"
                    favicon="/messenger/emoji.png"
                    canonical="/messenger"
                />
                <Header/>
                <div className="body-container">
                    <Title icon="/messenger/chat.svg" title="Messenger - Every Second"/>
                        <Since color="#2196F3"/>
                        <Cards cols={2} stats={stats}></Cards>
                </div>
                <Footer/>
            </>
        );
    }
}

export default Apple;