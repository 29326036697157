import React, { Component } from 'react';
import {Link} from "react-router-dom";
import LinkBox from "./LinkBox.js";
import Header from "./Header.js";
import Pages from "./Pages.js";
import MetaTags from "./MetaTags.js";

class Home extends Component {
    render() {
        return (
            <>
                <MetaTags 
                    title="Every Second"
                    description="Visualize all the things happening around the world every second!"
                    socialDesc="Visualize all the things happening around the world every second!"
                    canonical=""
                    favicon="/general/world.png"
                />
                <Header/>
                <div className="home-about">
                    Visualize all the amazing things happening every second around the world. Made by <a target="_blank" href="https://twitter.com/nealagarwal">Neal Agarwal</a>.
                </div>
                <div className="home-links">
                    {Pages.map(function(page){
                        return <LinkBox icon={page.icon} bgColor="white" color="#333333" page={page.link} text={page.title}/>
                    })}
                </div>
            </>
        );
    }
}

export default Home;