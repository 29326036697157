import React, { Component } from 'react';
import Cards from './Cards.js';
import Stat from './Stat.js';
import Since from './Since.js';
import Title from './Title.js';
import Header from './Header.js';
import Footer from './Footer.js';
import MetaTags from './MetaTags.js';
import * as StatHelpers from './StatHelpers.js';

let {year, quarter, month, day, statNum} = StatHelpers;

let stats = [
    new Stat(year(52500000), "/disney-world/girl.svg", "http://www.uscitytraveler.com/10-most-popular-theme-parks-in-the-world/", function(num){
        return <>{statNum(num)} total visitors</>;
    }),
    new Stat(year(20450000), "/disney-world/castle.svg", "https://magicguides.com/disney-world-statistics/", function(num){
        return <>{statNum(num)} Magic Kingdom visitors</>;
    }),
    new Stat(year(12500000), "/disney-world/parrot.svg", "https://magicguides.com/disney-world-statistics/", function(num){
        return <>{statNum(num)} Animal Kingdom visitors</>;
    }),
    new Stat(year(12200000), "/disney-world/roller-coaster.svg", "https://magicguides.com/disney-world-statistics/", function(num){
        return <>{statNum(num)} Epcot visitors</>;
    }),
    new Stat(year(10720000), "/disney-world/fence.svg", "https://magicguides.com/disney-world-statistics/", function(num){
        return <>{statNum(num)} Hollywood Studios visitors</>;
    }),
    new Stat(day(150000), "/disney-world/train.svg", "https://magicguides.com/disney-world-statistics/", function(num){
        return <>{statNum(num)} monorail riders</>;
    }),
    new Stat(year(792000000), "/disney-world/lawn-mower.svg", "https://www.themeparktourist.com/features/20140516/18027/12-ways-horticulture-cast-members-keep-walt-disney-world-looking-beautiful", function(num){
        return <>{statNum(num)} yards of grass mown</>;
    }),
    new Stat(day(285000), "/disney-world/laundry.svg", "https://www.themeparktourist.com/features/20140213/16265/13-repetitive-tasks-disney-cast-members-repeat-over-and-over-again", function(num){
        return <>{statNum(num)} pounds of laundry done</>;
    }),
    new Stat(day(150000), "/disney-world/camera.svg", "https://magicguides.com/disney-world-statistics/", function(num){
        return <>{statNum(num)} PhotoPass photos taken</>;
    }),
    new Stat(year(75000000), "/disney-world/soda.svg", "https://www.themeparktourist.com/features/20140213/16265/13-repetitive-tasks-disney-cast-members-repeat-over-and-over-again", function(num){
        return <>{statNum(num)} Coca-Colas consumed</>;
    }),
    new Stat(year(13000000), "/disney-world/water.svg", "https://www.themeparktourist.com/features/20140213/16265/13-repetitive-tasks-disney-cast-members-repeat-over-and-over-again", function(num){
        return <>{statNum(num)} water bottles sold</>;
    }),
    new Stat(year(10000000), "/disney-world/cheeseburger.svg", "https://www.themeparktourist.com/features/20140213/16265/13-repetitive-tasks-disney-cast-members-repeat-over-and-over-again", function(num){
        return <>{statNum(num)} hamburgers served</>;
    })/*,
    new Stat(year(6000000), "/disney-world/hot-dog.svg", "https://www.themeparktourist.com/features/20140213/16265/13-repetitive-tasks-disney-cast-members-repeat-over-and-over-again", function(num){
        return <>{statNum(num)} hotdogs served</>;
    }),
    new Stat(year(1600000), "/disney-world/drumstick.svg", "https://www.themeparktourist.com/features/20140213/16265/13-repetitive-tasks-disney-cast-members-repeat-over-and-over-again", function(num){
        return <>{statNum(num)} drumsticks served</>;
    })*/
];

class DisneyWorld extends Component {
    render() {
        return (
            <>
                <MetaTags 
                    title="Disney World - Every Second"
                    description="See how many people are visiting Disney World every second, and 10+ other stats!"
                    socialDesc="What's happening at Disney World every second?"
                    favicon="/disney-world/castle.png"
                    canonical="/disney-world"
                />
                <Header/>
                <div className="body-container">
                    <Title icon="/disney-world/castle.svg" title="Disney World - Every Second"/>
                        <Since color="#1e90ff"/>
                        <Cards cols={2} stats={stats}></Cards>
                </div>
                <Footer/>
            </>
        );
    }
}

export default DisneyWorld;