import React, { Component } from 'react';

class Since extends Component {

    constructor(props) {
        super(props);
        this.state = {secs: 0}
        this.tick = this.tick.bind(this);
        this.renderSecs = this.renderSecs.bind(this);
    }
    
    componentDidMount(){
        setInterval(this.tick, 1000);
    }

    tick(){
        let {secs} = this.state;
        secs += 1;
        this.setState({secs: secs});
    }

    renderSecs(){
        let {secs} = this.state;

        let minutes = Math.floor(secs/60);
        let seconds = secs % 60;

        if(minutes > 0){
            return `It has been ${minutes} minutes and ${seconds} seconds`;
        }else{
            return `It has been ${seconds} seconds`;
        }
    }

    render() {
        let {color} = this.props;
        return (
            <div style={{backgroundColor: color}} className="since">{this.renderSecs()}</div>
        );
    }
}

export default Since;