import React from 'react';

export default class AdComponent extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
        <ins className="adsbygoogle"
        style={{maxWidth: "900px", display:"block", marginLeft: "auto", marginRight: "auto"}}
        data-ad-client="ca-pub-4556406968269041"
        data-ad-slot="1461983300"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    );
  }
}