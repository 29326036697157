import React from 'react';

export function floor(num){
    return Math.floor(num);
}

export function minute(num){
    return num/60;
}

export function hour(num){
    return num/60/60;
}

export function day(num){
    return num/24/60/60;
}

export function week(num){
    return num/7/24/60/60;
}

export function month(num){
    return num/30/24/60/60;
}

export function months(num, months){
    return num/months/30/24/60/60;
}

export function quarter(num){
    return num/91/24/60/60;
}

export function year(num){
    return num/365/24/60/60;
}

export function years(num, years){
    return num/years/365/24/60/60;
}

export function statNum(num){
    return <span className="stat-num">{num}</span>
}