import React, { Component } from 'react';
import Title from './Title.js';
import Header from './Header.js';
import Footer from './Footer.js';

let planets = [];

function Planet(name, size, image){
    this.name = name;
    this.size = size/6955;
    this.image = image;
    planets.push(this);
}

new Planet("pluto", 2370, "/planet-sizes/pluto.svg");
new Planet("moon", 3475, "/planet-sizes/pluto.svg");
new Planet("mercury", 4879, "/planet-sizes/mercury.svg");
new Planet("mars", 6792, "/planet-sizes/mars.svg");
new Planet("venus", 12104, "/planet-sizes/venus.svg");
new Planet("earth", 12756, "/planet-sizes/earth.svg");
new Planet("neptune", 49528, "/planet-sizes/neptune.svg");
new Planet("uranus", 51118, "/planet-sizes/uranus.svg");
new Planet("saturn", 120536, "/planet-sizes/saturn.svg");
new Planet("jupiter", 142984, "/planet-sizes/jupiter.svg");
new Planet("sun", 1391000, "/planet-sizes/venus.svg");

class PlanetSizes extends Component {
    constructor(props) {
        super(props);

        let maxHeight = 0;
        for(let x = 0; x < planets.length; x++){
            if(planets[x].size > maxHeight){
                maxHeight = planets[x].size;
            }
        }

        let scale = this.getScale(0);
        let leftOffset = this.getLeftOffset(0, scale);
        let topOffset = this.getTopOffset(maxHeight, scale);

        this.state = {current: 0, maxHeight: maxHeight, 
                      leftOffset: leftOffset, scale: scale, 
                      topOffset: topOffset};

        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.getLeftOffset = this.getLeftOffset.bind(this);
    }

    getScale(current){
        return 200/planets[current].size;
    }

    getLeftOffset(current, scale){
        let container = document.getElementById("size-wrapper");
        if(container){
            console.log(container.clientWidth);
        }
        let leftOffset = container ? (container.clientWidth/2) - 100: 200;
        
        for(let x = 0; x < current; x++){
            leftOffset -= planets[x].size * scale + 20;
        }
        return leftOffset
    }

    getTopOffset(maxHeight, scale){
        return 400 - (maxHeight * scale);
    }

    prev(){
        let {current, maxHeight} = this.state;

        current--;

        let scale = this.getScale(current);
        let newLeftOffset = this.getLeftOffset(current, scale);
        let newTopOffset = this.getTopOffset(maxHeight, scale);

        this.setState({current: current, leftOffset: newLeftOffset, topOffset: newTopOffset, scale: scale});
    }
    
    next(){
        let {current, maxHeight} = this.state;

        current++;

        let scale = this.getScale(current);
        let newLeftOffset = this.getLeftOffset(current, scale);
        let newTopOffset = this.getTopOffset(maxHeight, scale);
    
        //this.tweenStart("leftOffset", newLeftOffset);
        //this.tweenStart("topOffset", newTopOffset);
        //this.tweenStart("scale", scale);

        this.setState({current: current, leftOffset: newLeftOffset, topOffset: newTopOffset, scale: scale});
    }

    render() {
        let {leftOffset, scale, topOffset, current} = this.state;
        
        let transition = "all .5s ease-in-out 0s";
        let springOptions = {stiffness: 60, damping: 15};

        return (
            <div>
                <Header/>
                <div className="body-container">
                    <Title icon="/apple/iphone.svg" title="The Scale of Space"/>
                    <div id="size-wrapper">
                            <div id="size-container" style={{
                                        marginLeft: `${leftOffset}px`,
                                        transform: `scale: (${scale})`,
                                        transition: transition}}>
                                {planets.map(function(p, i){
                                     let size = p.size;
                                     let display = i > current + 2 ? "hidden" : "visible";
                                     return <img key={"size"+i} 
                                                style={{transition: transition, 
                                                        width: size + 'px',
                                                        height: size + 'px'}} 
                                                className="planet" 
                                                src={p.image}/>
                                })}
                            </div>}
                    </div>
                    <button disabled={current == 0} onClick={this.prev}>Prev</button>
                    <button disabled={current == planets.length - 1} onClick={this.next}>Next</button>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default PlanetSizes;