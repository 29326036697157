import React, { Component } from 'react';
import Cards from './Cards.js';
import Stat from './Stat.js';
import Since from './Since.js';
import Title from './Title.js';
import Header from './Header.js';
import Footer from './Footer.js';
import MetaTags from './MetaTags.js';
import * as StatHelpers from './StatHelpers.js';

let {year, years, quarter, month, months, day, minute, statNum} = StatHelpers;

let stats = [
    new Stat(day(5000000000), "/youtube/play.svg", "https://bitsfrombytes.com/youtube-statistics/", function(num){
        return <>{statNum(num)} videos watched</>;
    }),
    new Stat(minute(500), "/youtube/film-reel.svg", "https://www.tubefilter.com/2019/05/07/number-hours-video-uploaded-to-youtube-per-minute/", function(num){
        return <>{statNum(num)} hours of video uploaded</>;
    }),
    new Stat(day(1000000000), "/youtube/television.svg", "https://techcrunch.com/2017/02/28/people-now-watch-1-billion-hours-of-youtube-per-day/", function(num){
        return <>{statNum(num)} hours of video watched</>;
    }),
    new Stat(year(50000000000), "/youtube/ghost.svg", "https://venturebeat.com/2018/12/08/youtube-game-videos-were-viewed-for-50-billion-hours-in-2018/", function(num){
        return <>{statNum(num)} hours of gaming content watched</>;
    }),
    new Stat(year(12000000000), "/youtube/coin.svg", "https://www.thestreet.com/investing/youtube-might-be-worth-over-100-billion-14586599", function(num){
        return <>${statNum(num)} in revenue</>;
    }),
    new Stat(years(2000000000, 5), "/youtube/no-camera.svg", "https://www.youtube.com/yt/about/press/", function(num){
        return <>${statNum(num)} claimed through Content ID</>;
    }),
];

class YouTube extends Component {

    componentDidMount(){
        
    }

    render() {
        return (
            <>
                <MetaTags 
                    title="Youtube - Every Second"
                    description="See how many hours of video are watched on YouTube every second. Plus more interesting YouTube stats!"
                    socialDesc="How many hours of video are watched on YouTube every second?"
                    favicon="/youtube/play.png"
                    canonical="/youtube"
                />
                <Header/>
                <div className="body-container">
                    <Title icon="/youtube/play.svg" title="YouTube - Every Second"/>
                        <Since color="#E16B5A"/>
                        <Cards cols={2} stats={stats}></Cards>
                </div>
                <Footer/>
            </>
        );
    }
}

export default YouTube;